@mixin font-face($font-family, $file-path, $font-weight: null, $font-style:null) {
	@font-face {
		font-family: $font-family;
			src: url('#{$file-path}.woff2') format('woff2'),
				 url('#{$file-path}.woff') format('woff');
				 // url('#{$file-path}.ttf') format('truetype');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}
