/*
	Examples
	.class-1 { @include aspect-ratio(); } 				No arguments defaults to a 1:1 ratio
	.class-2 { @include aspect-ratio(16, 9); } 			Width and height
	.class-3 { @include aspect-ratio(1.777778); } 		Ratio (calculated width/height)
	.class-4 { @include aspect-ratio(4px, 3px); } 		Comparable units
	.class-5 { @include aspect-ratio($ratio: 1.2); } 	Keywords
*/

@mixin aspect-ratio($arglist...) {

	$map : keywords($arglist);
	$height: map-get($map, height) or nth-or-null($arglist, 2);
	$width:  map-get($map, width)  or nth-or-null($arglist, 1);
	$ratio:  map-get($map, ratio)  or if($width and $height, $width/$height, nth-or-null($arglist, 1)) or 1;
	$padding: 1 / $ratio * 100%;
	$selector: map-get($map, selector) or "> :first-child";

	&:before { content: ' '; float:left; padding-top: $padding;  }
	&:after  { content: ' '; display:table; clear: both; }
}


// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-or-null($list, $index) {
	@return if(length($list) >= $index, nth($list, $index), null);
}
