@mixin mq($size, $type: min, $orientation: width) {
	@if map_has_key($breakpoints, $size) {
		$size: map_get($breakpoints, $size);
	}
	@if $type == max {
		$size: $size - 1px;
	}
	@if $orientation == width {
		@media only screen and (#{$type}-width: $size) {
			@content;
		}
	}
	@if $orientation == height {
		@media only screen and (#{$type}-height: $size) {
			@content;
		}
	}
}
