////////////////////////////////////////////////////////
// Font Imports
////////////////////////////////////////////////////////

@include font-face('GT Super Display', '../../fonts/GT-Super-Display-Light', 200);
@include font-face(
  'GT Super Display',
  '../../fonts/GT-Super-Display-Regular',
  400
);
@include font-face('GT Super Display', '../../fonts/GT-Super-Display-Medium', 500);
@include font-face('GT Super Display', '../../fonts/GT-Super-Display-Bold', 700);

@include font-face(
  'GT Super Display',
  '../../fonts/GT-Super-Display-Light-Italic',
  200,
  italic
);
@include font-face(
  'GT Super Display',
  '../../fonts/GT-Super-Display-Medium-Italic',
  500,
  italic
);

@include font-face(
  'GT Super Display',
  '../../fonts/GT-Super-Display-Regular-Italic',
  normal,
  italic
);
@include font-face(
  'GT Super Display',
  '../../fonts/GT-Super-Display-Bold-Italic',
  700,
  italic
);

@include font-face('Roboto', '../../fonts/Roboto-Light', 200);
@include font-face('Roboto', '../../fonts/Roboto-Regular', 400);
@include font-face('Roboto', '../../fonts/Roboto-Medium', 500);
@include font-face('Roboto', '../../fonts/Roboto-Bold', 700);

////////////////////////////////////////////////////////
// Base Styles
////////////////////////////////////////////////////////

html {
  font-size: var(--base-font-size);
}

body {
  font-variant-ligatures: normal;
  -webkit-font-smoothing: antialiased;
  background-color: var(--page-background-color);
  min-height: 100vh;
  color: var(--black-primary);
  overflow-x: hidden;
}

////////////////////////////////////////////////////////
// Body Copy
////////////////////////////////////////////////////////

@mixin body() {
  font-family: var(--body-font);
  font-weight: var(--font-weight-regular);
  font-size: var(--size-body);
  letter-spacing: var(--letter-spacing-body);
  line-height: var(--line-height-body);
}

body,
.copy--body {
  @include body();
}

// Large

@mixin body-large() {
  font-family: var(--body-font);
  font-weight: var(--font-weight-regular);
  font-size: var(--size-body-large);
  letter-spacing: var(--letter-spacing-body-large);
  line-height: var(--line-height-body-large);
}

p.large,
.copy--large {
  &,
  & a {
    @include body-large();
  }
}

// Small

@mixin body-small() {
  font-family: var(--body-font);
  font-weight: var(--font-weight-regular);
  font-size: var(--size-body-small);
  letter-spacing: var(--letter-spacing-body-small);
  line-height: var(--line-height-body-small);
}

p.small,
.copy--small {
  &,
  & a {
    @include body-small();
  }
}

// Weight Overrides

@mixin body-strong() {
  @include body();
  font-weight: var(--font-weight-medium);
}

////////////////////////////////////////////////////////
// Headings
////////////////////////////////////////////////////////

@mixin heading($i) {
  font-family: var(--serif-font);
  font-size: var(--size-h#{$i});
  font-weight: var(--font-weight-h#{$i});
  letter-spacing: var(--letter-spacing-h#{$i});
  line-height: var(--line-height-h#{$i});
}

// h1
@mixin h1() {
  @include heading(1);
}

// h2
@mixin h2() {
  @include heading(2);
}

// h3
@mixin h3() {
  @include heading(3);
}

// h4
@mixin h4() {
  @include heading(4);
}

// h5
@mixin h5() {
  @include heading(5);
}

// h6
@mixin h6() {
  @include heading(6);
}

// eyebrow
@mixin eyebrow() {
  font-family: var(--body-font);
  font-size: var(--size-eyebrow);
  font-weight: var(--font-weight-eyebrow);
  letter-spacing: var(--letter-spacing-eyebrow);
  line-height: var(--line-height-eyebrow);
  text-transform: uppercase;
}

// DOM Tags

h1,
.h1,
h1 a,
.h1 a {
  @include h1();
}

h2,
.h2,
h2 a,
.h2 a {
  @include h2();
}

h3,
.h3,
h3 a,
.h3 a {
  @include h3();
}

h4,
.h4,
h4 a,
.h4 a {
  @include h4();
}

h5,
.h5,
h5 a,
.h5 a {
  @include h5();
}

h6,
.h6,
h6 a,
.h6 a {
  @include h6();
}

////////////////////////////////////////////////////////
// Additional Headings
////////////////////////////////////////////////////////

.eyebrow {
  @include eyebrow();
}

////////////////////////////////////////////////////////
// CTA Font Styles
////////////////////////////////////////////////////////

@mixin cta() {
  font-family: var(--font-family-cta);
  font-size: var(--size-cta);
  font-weight: var(--font-weight-cta);
  letter-spacing: var(--letter-spacing-cta);
  line-height: var(--line-height-cta);
}

@mixin cta-rect() {
  font-family: var(--font-family-cta-rect);
  font-size: var(--size-cta-rect);
  font-weight: var(--font-weight-cta-rect);
  letter-spacing: var(--letter-spacing-cta-rect);
  line-height: var(--line-height-cta-rect);
}

a,
button,
.cta {
  @include cta();
}

.cta-rect {
  @include cta-rect();
}

// CTA Button & Interaction Styling

@mixin a() {
  color: inherit;
  text-decoration: none;

  @include mq('medium') {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

a {
  @include a();
}

////////////////////////////////////////////////////////
// Form Font Styles
////////////////////////////////////////////////////////

@mixin input() {
  font-family: var(--font-family-input);
  font-size: var(--size-input);
  font-weight: var(--font-weight-input);
  letter-spacing: var(--letter-spacing-input);
  line-height: var(--line-height-input);
}

// Input

input,
textarea,
.input {
  @include input();
}

////////////////////////////////////////////////////////
// Logo Font Styles
////////////////////////////////////////////////////////

@mixin logo() {
  font-family: var(--font-family-logo);
  font-size: var(--size-logo);
  font-weight: var(--font-weight-logo);
  letter-spacing: var(--letter-spacing-logo);
  line-height: var(--line-height-logo);
}

// Logo

.logo {
  @include logo();
}

////////////////////////////////////////////////////////
// Modifiers
////////////////////////////////////////////////////////

em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

// p.strong,
// p strong {
//   @include body-strong();
// }

p.compact {
  line-height: 1.375;
}

// Font Variations

.font--serif {
  font-family: var(--serif-font);
  font-weight: var(--font-weight-regular);
}

.font--sans {
  font-family: var(--body-font);
  font-weight: var(--font-weight-regular);
}

// Text Transformations

.caps {
  text-transform: uppercase;
}

// meta

.meta {
  font-size: var(--size-meta);
  line-height: 2;
}

.wp-block-quote p:first-child {
  @include h4();

  &:before {
    content: "\201C";
    display: block;
    text-align: center;
    font-size: 3em;
    line-height: 0.5;

    @include mq('medium') {
        font-size: 2em;
    }
  }
}

////////////////////////////////////////////////////////
// MUI Form
////////////////////////////////////////////////////////
.MuiFormControl-root {
  .MuiSelect-root {
    padding-bottom: 8px;
    padding-top: 7px;
  }
  .MuiFormHelperText-root {
    &.Mui-error {
      position: absolute;
      bottom: -2em;
      margin: 0;
    }
  }
}
