.container {
  width: 100%;
  padding: 0 var(--grid-padding);
  margin-left: auto;
  margin-right: auto;

  .is-ie & {
    padding: 0 3.2rem;
  }

	@include mq('medium') {
    padding: 0 var(--grid-padding);

    .is-ie & {
      padding: 0 10rem;
    }

    &--wide {
      padding: 0 var(--grid-padding);
    }

    &--narrow {
      padding: 0 calc(#{var(--grid-padding)} + #{grid(1, true)});
    }

    &--offset {
      padding: 0;
      width: calc(100% - (#{var(--grid-padding)} * 2));
    }

    &--offset-left {
      padding: 0 var(--grid-padding) 0 calc(#{var(--grid-padding)} + #{grid(1, true)});
    }
  }

	@include mq('large') {
    padding: 0 calc(#{var(--grid-padding)} + #{grid(1, true)});

    .is-ie & {
      padding: 0 18rem;
    }

    &--wide {
      padding: 0 var(--grid-padding);
    }

    &--narrow {
      padding: 0 calc(#{var(--grid-padding)} + #{grid(2, true)});
    }

    &--offset {
      padding: 0;
      width: calc(100% - (#{var(--grid-padding)} * 2));
    }

    &--offset-left {
      padding: 0 var(--grid-padding) 0 calc(#{var(--grid-padding)} + #{grid(1, true)});
    }
  }
}

.module:not(.module--0) {
  margin-top: var(--spacer-module-default);
  margin-bottom: var(--spacer-module-default);
}

.bg--light {
  background-color: #faf8f5;
}

.bg--medium {
  &:not(.module--0) {
    padding-top: var(--spacer-module-large);
    padding-bottom: var(--spacer-module-large);

    & + .bg--medium {
      margin-top: calc(var(--spacer-module-default) * -1);
    }
  }
  &.module.module--1 {
    padding-bottom: var(--spacer-module-default);
  }
  &.module:last-child {
    margin-bottom: 0;
  }
  background-color: #f2f0ec;
}

.bg--dark {
  background-color: #1f1f1f;
}

.grid {
  display: flex;
  flex-wrap: wrap;

  &--center {
    align-items: center;
    justify-content: center;
  }

  &--space-between {
    justify-content: space-between;
  }
}

img {
  max-width: 100%;
}

.col-lg-hidden {
  @include mq('large') {
    display: none !important;
  }
}

.col-md-hidden {
  @include mq('medium') {
    display: none !important;
  }
}

.col-sm-hidden {
  @include mq('small') {
    display: none !important;
  }
}

.color--gray {
  color: var(--gray);
}
.color--light-gray {
  color: var(--light-gray);
}
.color--body {
  color: var(--black-primary);
}

// Aspect ratios for images/video containers

[class*='ratio'] {
  position: relative;
  width: 100%;

  img,
  video {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ratio-1x1 {
  @include aspect-ratio();
}

.ratio-2x3 {
  @include aspect-ratio(2, 3);
}

.ratio-3x2 {
  @include aspect-ratio(3, 2);
}

.ratio-3x4 {
  @include aspect-ratio(3, 4);
}

.ratio-16x9 {
  @include aspect-ratio(16, 9);
}
