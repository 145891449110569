////////////////////////////////////////////////////////
// Breakpoints
////////////////////////////////////////////////////////

$breakpoints: (
	'bitty':				321px,
	'tiny':					480px,
	'small':				720px,
	'medium':				1024px,
	'large':				1280px,
	'xlarge':				1440px,
	'xxlarge':				1680px,
	'xxxlarge':				1920px
);

:root {

	////////////////////////////////////////////////////////
	// Animation Easing
	////////////////////////////////////////////////////////

	--ease-in: 							cubic-bezier(0.490, 0.025, 0.650, 0.650);
	--ease-out: 						cubic-bezier(0.280, 0.440, 0.490, 1.000);
	--ease-in-out: 						cubic-bezier(0.490, 0.025, 0.490, 1.000);

	--ease-in-soft: 					cubic-bezier(0.490, 0.025, 0.685, 1.000);
	--ease-out-soft: 					cubic-bezier(0.280, 0.000, 0.490, 1.000);
	--ease-in-out-soft:					cubic-bezier(0.720, 0.000, 0.280, 1.000);

	--ease-out-jim: 					cubic-bezier(0.420, 0.000, 0.002, 1.000);
	--ease-out-jim-fast: 				cubic-bezier(0.000, 0.000, 0.002, 1.000);

	////////////////////////////////////////////////////////
	// Colors
	////////////////////////////////////////////////////////

	--page-background: 					#FAF8F5;
	--white: 							#FFFDFA;
	--white-bridal-heath: 				#FFFAF4;
	--brown-soft-amber: 				#CEC2B4;
	--brown-pablo: 						#7B7060;
	--orange-persimmon: 				#FF6350;
	--orange-outrageous: 				#FF5834;
	--black-dune: 						#2C2926;
	--black: 							#000000;
	--gray: 							#7E7E7E;
	--light-gray: 						#9C9C9C;
	--black-primary: 					#202124;

	////////////////////////////////////////////////////////
	// Grid
	////////////////////////////////////////////////////////

	--grid-padding: 3.2rem;
	--grid-column-count: 4;
	--grid-gutter: 2rem;
	--grid-column-count: 6;
	--grid-width: 100vw;
	--grid-height: 100vh;

	@include mq('tiny') {
		--grid-column-count: 6;
		--grid-padding: 5rem;
	}

	@include mq('small') {
		--grid-column-count: 8;
		--grid-padding: 6rem;
	}

	@include mq('medium') {
		--grid-padding: 10rem;
		--grid-column-count: 12;
	}

	@include mq('large') {
		--grid-padding: 18rem;
	}

	////////////////////////////////////////////////////////
	// Typography
	////////////////////////////////////////////////////////

	--body-font: 						'Roboto', sans-serif;
	--serif-font: 						'GT Super Display', serif;

	--base-font-size: 					62.5%;
	--font-weight-regular: 				400;
	--font-weight-medium: 				500;
	--font-weight-semibold: 			600;
	--font-weight-bold: 				700;

	// Headings

	--size-h1: 							2.8rem;
	--size-h2: 							2.6rem;
	--size-h3: 							2.4rem;
	--size-h4: 							2rem;
	--size-h5: 							2rem;
	--size-h6: 							1.6rem;
	--size-eyebrow: 					1.2rem;
	--size-meta: 						1rem;

	--line-height-h1: 					1.3571428571;
	--line-height-h2: 					1.3846153846;
	--line-height-h3: 					1.3333333333;
	--line-height-h4: 					1.4;
	--line-height-h5: 					1.4;
	--line-height-h6: 					1.375;
	--line-height-eyebrow: 				1.1666666667;

	--letter-spacing-h1: 				0;
	--letter-spacing-h2: 				0;
	--letter-spacing-h3: 				0;
	--letter-spacing-h4: 				0;
	--letter-spacing-h5: 				0;
	--letter-spacing-h6: 				0;
	--letter-spacing-eyebrow: 			0.125em;

	--font-weight-h1: 					400;
	--font-weight-h2: 					400;
	--font-weight-h3: 					400;
	--font-weight-h4: 					400;
	--font-weight-h5: 					400;
	--font-weight-h6: 					400;
	--font-weight-eyebrow: 				500;

	@include mq('medium') {
		--size-h1: 						#{vw(74)};	// 7.4rem
		--size-h2: 						#{vw(64)};	// 7.2rem
		--size-h3: 						#{vw(44)};	// 4.8rem
		--size-h4: 						#{vw(32)};	// 3.6rem
		--size-h5: 						2.4rem;
		--size-h6: 						1.8rem;

		--line-height-h1: 				1.1891891892;
		--line-height-h2: 				1.1875;
		--line-height-h3: 				1.3181818182;
		--line-height-h4: 				1.34375;
		--line-height-h5: 				1.4166666667;
		--line-height-h6: 				1.3888888889;

		--letter-spacing-h1: 			-0.01351351351em;
		--letter-spacing-h2: 			-0.0078125em;
		--letter-spacing-h3: 			-0.01136363636em;
	}

	// Body Copy

	--size-body: 						1.4rem;
	--line-height-body: 				1.7142857143; // 24/14
	--letter-spacing-body: 				0;

	--size-body-large:					1.6rem;
	--line-height-body-large:			1.75; // 28/16
	--letter-spacing-body-large: 		0;

	--size-body-small:					1.4rem;
	--line-height-body-small:			1.7142857143; // 24/14
	--letter-spacing-body-small: 		0;

	@include mq('large') {
		--size-body: 					1.6rem;
		--line-height-body: 			1.75; // 28/16

		--size-body-large: 				1.8rem;
		--line-height-body-large:		1.6666666667; // 30/18

		--line-height-body-small:		1.8571428571; // 26/14
	}

	// CTA's

	--font-family-cta: 					var(--body-font);
	--font-weight-cta: 					400;
	--letter-spacing-cta: 				0;
	--line-height-cta: 					1;
	--size-cta: 						1em;

	--font-family-cta-rect: 			var(--body-font);
	--font-weight-cta-rect: 			var(--font-weight-semibold);
	--letter-spacing-cta-rect: 			0.0075em;
	--line-height-cta-rect: 			1.1666666667;
	--size-cta-rect: 					1.2rem;

	// Forms

	--font-family-input: 				var(--body-font);
	--font-weight-input:				var(--font-weight-roman);
	--letter-spacing-input: 			0.009166666667em;
	--line-height-input: 				2.0;
	--size-input: 						1.2rem;

	@include mq('large') {
		--letter-spacing-input: 		0.0095em;
		--line-height-input: 			1.2;
		--size-input: 					2.0rem;
	}

	// Logo

	--font-family-logo: 				var(--body-font);
	--font-weight-logo: 				var(--font-weight-semibold);
	--letter-spacing-logo: 				0.0em;
	--line-height-logo: 				1.0;
	--size-logo: 						3.2rem;

	@include mq('large') {
		--letter-spacing-logo: 			0.0em;
		--line-height-logo: 			1.0;
		--size-logo: 					3.2rem;
	}

	////////////////////////////////////////////////////////
	// Zs
	////////////////////////////////////////////////////////

	--z-back: 							0;
	--z-default:						1;
	--z-ui-overlay:	 					20;
	--z-menu:	 						50;
	--z-modal:	 						100;
	--z-loader:	 						500;
	--z-top:	 						1000;

	////////////////////////////////////////////////////////
	// Global color schemes
	////////////////////////////////////////////////////////

	--page-background-color:			var(--page-background);
	--page-text-ui-color:				var(--black-dune);
	--product-title-background-color:	var(--black-dune);
	--product-title-text-color:			var(--white);
	--top-nav-color:					var(--black-dune);
	--upscroll-nav-backround-color:		var(--white-bridal-heath);
	--upscroll-nav-text-color:			var(--black-dune);

}
