// Keyframe Animations

@keyframes PostCards {
    0%{background-position:0% 0%}
    50%{background-position:93% 100%}
    100%{background-position:0% 0%}
}

@keyframes sk-circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

@keyframes autofill {
	100% {
		background: transparent;
		color: currentColor;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade-in-up {
	0% {
		opacity: 0;
		transform: translateY(3rem);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fade-in-up-lg {
	0% {
		opacity: 0;
		transform: translateY(5rem);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fade-in-up-100 {
	0% {
		transform: translate3d(0, 100%, 0);
	}
	100% {
		transform: none;
	}
}

@keyframes fade-in-up-pdp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fade-out-down-100 {
	0% {
		transform: translate3d(0%, 0%, 0);
	}
	100% {
		transform: translate3d(0%, 100%, 0);
	}
}

@keyframes fade-out-down-50 {
	0% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 0;
		transform: translateY(50%);
	}
}

@keyframes fade-out-down-25 {
	0% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 0;
		transform: translateY(25%);
	}
}

@keyframes fade-out-down-10 {
	0% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 0;
		transform: translateY(10%);
	}
}

@keyframes fade-in-up-opaque {
	0% {
		opacity: 0;
		transform: translateY(3rem);
	}
	100% {
		opacity: 0.5;
		transform: none;
	}
}

@keyframes fade-out-up-100 {
	0% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 0;
		transform: translateY(-100%);
	}
}

@keyframes fade-out-up {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

@keyframes zoom-in-hero {
	0% {
		opacity: 0;
		transform: scale3d(1.2, 1.2, 1);
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

@keyframes zoom-in {
	0% {
		opacity: 0;
		transform: scale3d(0.9,0.9,1);
	}
	100% {
		opacity: 1;
		transform: scale3d(1,1,1);
	}
}

@keyframes show {
	0% {
		transform: scale(0.95);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes hide {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.95);
		opacity: 0;
	}
}

@keyframes ticker {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
