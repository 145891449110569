html {
	--bg-primary: #faf8f5;
	--color-primary: #2f2f2e;

	--spacer-module-small: 4rem;
	--spacer-module-default: 6rem;
	--spacer-module-large: 6rem;

	@include mq('small') {
		--spacer-module-small: #{vw(50)};
		--spacer-module-default: #{vw(100)};
		--spacer-module-large: #{vw(150)};
	}

	background-color: var(--bg-primary);
	color: var(--color-primary);
	backface-visibility: hidden;
	transition: background-color 1s var(--ease-in-out-soft);
}

body.is-loading {
	overflow: hidden;
	pointer-events: none;
}

body.is-locked {
	overflow: hidden;
}

.transition-group {
	visibility: hidden;

	@include mq('medium', 'max') {
		opacity: 0;
	}

	&.is-transition-in {
		visibility: visible;

		@include mq('medium', 'max') {
			animation: fade-in 0.5s var(--ease-out-jim) forwards;
		}
	}
}

.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: var(--bg-primary);
	z-index: 2000;
	display: flex;
	justify-content: center;
	align-items: center;

	figure {
		width: 15rem;
		height: auto;
		overflow: hidden;

		@include mq('small') {
			width: 20rem;
		}
	}

	&.is-out {
		animation: fade-out 500ms var(--ease-out-jim) forwards;
	}
}

.split-heading {
	&.is-disabled {
		.split-heading__word {
			transition: none !important;
			transform: none;
		}
	}

	@include mq('medium') {
		&__word {
			transform: translate3d(0, 100%, 0);
			will-change: transform;

			@for $i from 1 through 12 {
				&:nth-child(#{$i}) {
					animation-delay: #{$i * 0.06 - 0.06}s;
				}
			}
		}

		&__line {
			overflow: hidden;

			&:nth-child(2) {
				.split-heading__word {
					@for $i from 1 through 12 {
						&:nth-child(#{$i}) {
							animation-delay: #{$i * 0.06 + 0.12}s;
						}
					}
				}
			}

			&:nth-child(3) {
				.split-heading__word {
					@for $i from 1 through 12 {
						&:nth-child(#{$i}) {
							animation-delay: #{$i * 0.06 + 0.24}s;
						}
					}
				}
			}
		}
	}
}

.split-heading-2 {
	&.is-disabled {
		.split-heading__word {
			transition: none !important;
			transform: none;
			opacity: 1;
		}
	}

	@include mq('medium') {
		.split-heading__word {
			@for $i from 1 through 12 {
				&:nth-child(#{$i}) {
					animation-delay: #{$i * 0.06 + 0.12}s;
				}
			}
		}

		.split-heading__line {
			&:nth-child(2) {
				.split-heading__word {
					@for $i from 1 through 12 {
						&:nth-child(#{$i}) {
							animation-delay: #{$i * 0.06 + 0.18}s;
						}
					}
				}
			}
		}
	}
}

.split-heading-hero {
	&.is-disabled {
		.split-heading__word {
			transition: none !important;
			transform: none;
			opacity: 1;
		}
	}

	@include mq('medium') {
		.split-heading__word {
			@for $i from 1 through 12 {
				&:nth-child(#{$i}) {
					animation-delay: #{$i * 0.06 - 0.06}s;
				}
			}
		}

		.split-heading__line {
			&:nth-child(2) {
				.split-heading__word {
					@for $i from 1 through 12 {
						&:nth-child(#{$i}) {
							animation-delay: #{$i * 0.06 + 0.12}s;
						}
					}
				}
			}
		}
	}
}

.split-heading-hero-2 {
	&.is-disabled {
		.split-heading__word {
			transition: none !important;
			transform: none;
			opacity: 1;
		}
	}

	@include mq('medium') {
		.split-heading__word {
			@for $i from 1 through 12 {
				&:nth-child(#{$i}) {
					animation-delay: #{$i * 0.06 + 0.16}s;
				}
			}
		}

		.split-heading__line {
			&:nth-child(2) {
				.split-heading__word {
					@for $i from 1 through 12 {
						&:nth-child(#{$i}) {
							animation-delay: #{$i * 0.06 + 0.22}s;
						}
					}
				}
			}
		}
	}
}

.split-heading-chars {
	overflow: hidden;

	@include mq('medium') {
		&__char {
			transform: translateY(50%);
			opacity: 0;
			will-change: transform, opacity;
			overflow: visible;

			@for $i from 1 through 20 {
				&:nth-child(#{$i}) {
					animation-delay: #{$i * 0.06 - 0.06}s;
				}
			}
		}
	}
}

.sk-fading-circle {
	margin: 10rem auto;
	width: 4rem;
	height: 4rem;
	position: relative;

	.sk-circle {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;

		&:before {
			content: '';
			display: block;
			margin: 0 auto;
			width: 15%;
			height: 15%;
			background-color: #2f2f2e;
			border-radius: 100%;
			-webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
			animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
		}
	}

	.sk-circle2 {
		-webkit-transform: rotate(30deg);
		-ms-transform: rotate(30deg);
		transform: rotate(30deg);
	}
	.sk-circle3 {
		-webkit-transform: rotate(60deg);
		-ms-transform: rotate(60deg);
		transform: rotate(60deg);
	}
	.sk-circle4 {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
	.sk-circle5 {
		-webkit-transform: rotate(120deg);
		-ms-transform: rotate(120deg);
		transform: rotate(120deg);
	}
	.sk-circle6 {
		-webkit-transform: rotate(150deg);
		-ms-transform: rotate(150deg);
		transform: rotate(150deg);
	}
	.sk-circle7 {
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	.sk-circle8 {
		-webkit-transform: rotate(210deg);
		-ms-transform: rotate(210deg);
		transform: rotate(210deg);
	}
	.sk-circle9 {
		-webkit-transform: rotate(240deg);
		-ms-transform: rotate(240deg);
		transform: rotate(240deg);
	}
	.sk-circle10 {
		-webkit-transform: rotate(270deg);
		-ms-transform: rotate(270deg);
		transform: rotate(270deg);
	}
	.sk-circle11 {
		-webkit-transform: rotate(300deg);
		-ms-transform: rotate(300deg);
		transform: rotate(300deg);
	}
	.sk-circle12 {
		-webkit-transform: rotate(330deg);
		-ms-transform: rotate(330deg);
		transform: rotate(330deg);
	}
	.sk-circle2:before {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}
	.sk-circle3:before {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}
	.sk-circle4:before {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}
	.sk-circle5:before {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}
	.sk-circle6:before {
		-webkit-animation-delay: -0.7s;
		animation-delay: -0.7s;
	}
	.sk-circle7:before {
		-webkit-animation-delay: -0.6s;
		animation-delay: -0.6s;
	}
	.sk-circle8:before {
		-webkit-animation-delay: -0.5s;
		animation-delay: -0.5s;
	}
	.sk-circle9:before {
		-webkit-animation-delay: -0.4s;
		animation-delay: -0.4s;
	}
	.sk-circle10:before {
		-webkit-animation-delay: -0.3s;
		animation-delay: -0.3s;
	}
	.sk-circle11:before {
		-webkit-animation-delay: -0.2s;
		animation-delay: -0.2s;
	}
	.sk-circle12:before {
		-webkit-animation-delay: -0.1s;
		animation-delay: -0.1s;
	}
}

.react-modal {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 6;
	overflow-y: scroll;
}

// TODO: Don't use important (Used for mobile drag sliders)
.is-user-touch,
.is-user-touch .touch-container {
	width: 100%;
	margin-left: 0 !important;
	margin-right: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

#TockWidget {
	background: rgba(0, 0, 0, 0.3);
}

#TockLoader {
	position: absolute;
	top: 0;
	left: 50%;
	min-height: auto;
	height: 95%;
	width: 72rem;
	padding: 2.4rem 0;
	transform: translateX(-50%);
	overflow: hidden;
	z-index: -1;
}

#TockLoaderWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	.sk-fading-circle {
		width: 4rem;
		height: 4rem;

		@include mq('medium') {
			width: 6rem;
			height: 6rem;
		}

		.sk-circle:before {
			background-color: #fff;
		}
	}
}
